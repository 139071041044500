<div class="d-flex flex-row flex-wrap justify-content-start align-items-stretch elfa-container white-bg">
  <div class="flex-100">
    <div class="d-flex flex-row flex-wrap justify-content-start align-items-start">
      <div class="flex-100 flex-fill">
        <h1>{{currentBlogCategory?.name}}</h1>
      </div>
      <div class="flex-100 d-flex flex-row flex-wrap post-list">
        <div [@fadeInUp] *ngFor="let post of currentBlogCategory?.posts; let i = index;" class="flex-50 flex-md-25 px-2">
          <a [routerLink]="[post.routerLink]" class="title">
            <img *ngIf="post.pictureUrl" [defaultImage]="settings?.holdingImage?.small" [lazyLoad]="post.pictureUrl" [alt]="post.title">
            <img *ngIf="!post.pictureUrl && post.homePictureUrl" [defaultImage]="settings?.holdingImage?.small" [lazyLoad]="post.homePictureUrl" [alt]="post.title">
            <img *ngIf="!post.pictureUrl && !post.homePictureUrl" src="assets/images/logo/logo.jpg" [alt]="post.title">
            <span class="post-date">{{post.createdOn | date:'mediumDate'}}</span>
            <span class="post-title">{{post.title}}</span>
            <p class="py-1 post-overview">{{post.bodyOverview}}</p>
          </a>
        </div>
      </div>
    </div>
  </div>
</div>
