import { Component, Input, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { fadeInLeftAnimation } from 'src/@omnial/_animations/fade-in-left.animation';
import { fadeInRightAnimation } from 'src/@omnial/_animations/fade-in-right.animation';
import { fadeInUpAnimation } from 'src/@omnial/_animations/fade-in-up.animation';
import { scaleInAnimation } from 'src/@omnial/_animations/scale-in.animation';
import { Blog, BlogCategory, BlogPost } from 'src/@omnial/_models/catalog/blog.model';
import { BlogService } from 'src/@omnial/_services/catalog/blog.service';
import { AppSettings } from 'src/app/app.settings';

@Component({
  selector: 'app-blog-category',
  templateUrl: './../../../app/pages/blog-category/blog-category.component.html',
  styleUrls: ['./../../../app/pages/blog-category/blog-category.component.scss'],
  animations: [fadeInUpAnimation, fadeInLeftAnimation, fadeInRightAnimation, scaleInAnimation]
})

export class BlogCategoryComponent implements OnInit {
  @Input() blog: Blog;
  @Input() currentBlogCategory: BlogCategory;
  @Input() blogCategoryId: number;
  public searchedPosts: BlogPost[] = [];
  public showSearchResults = false;
  private subscriptions: Subscription[] = []

  constructor(public settings: AppSettings,
    public blogService: BlogService) { }

  ngOnInit(): void {
    if (this.blogCategoryId && !this.currentBlogCategory) {
      this.subscriptions.push(this.blogService.blog.subscribe(
        res => {
          if (res) {
            this.blog = res as Blog;
            this.blog.categories.forEach((category) => {
              if (category.id === this.blogCategoryId) {
                this.currentBlogCategory = category;
              }
            });
          } else {
            this.blogService.load();
          }
        }));
    }
  }

  public searchResults(posts: BlogPost[]): void {
    this.searchedPosts = posts;
    this.showSearchResults = true;
  }
}

