import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BlogCategoryComponent } from './blog-category.component';
import { MaterialBaseModule } from 'src/@omnial/material.base.module';
import { RouterModule } from '@angular/router';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { MaterialExtendModule } from 'src/@omnial/material.extend.module';
import { ClientSharedModule } from 'src/app/shared/_client/client.shared.module';
import { TopicContentModule } from 'src/@omnial/shared/topic-content/topic-content.module';
import { BlogSearchModule } from 'src/@omnial/shared/blog-search/blog-search.module';
import { BlogCategoryRoutingModule } from './blog-category-routing.module';
import { ClientPageModule } from 'src/app/pages/_client/client.page.module';
import { ProductCollectionsModule } from 'src/@omnial/shared/product-collections/product-collections.module';


@NgModule({
  imports: [
    BlogCategoryRoutingModule,
    CommonModule,
    MaterialBaseModule,
    MaterialExtendModule,
    RouterModule,
    LazyLoadImageModule,
    TopicContentModule,
    ClientSharedModule,
    ClientPageModule,
    BlogSearchModule,
    ProductCollectionsModule
  ],
  declarations: [BlogCategoryComponent],
  exports: [BlogCategoryComponent]
})
export class BlogCategoryModule { }
